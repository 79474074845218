<template>
  <MasterTable
    title="Fordonsskadekoder"
    :rows="vehicleDefectCodes"
    :columns="columns"
    :loading="loading"
    :add="add"
    :update="update"
    :remove="remove"
    :reset-form-model="resetFormModel"
    :form-model="model"
    :export-data="exportData"
    remove-item-text-prop="description"
    remove-identifier="defectCode"
    @update-form-model="(row) => (model = row)"
  >
    <template v-slot:form>
      <div class="row" style="min-width: 300px">
        <div class="col-12 q-pa-md">
          <q-input
            dense
            v-model="model.defectCode"
            label="Skadekod"
            :rules="[requiredRule]"
          />
        </div>
        <div class="col-12 q-pa-md">
          <q-input dense v-model="model.description" label="Beskrivning" />
        </div>
        <div class="col-12 q-pa-md">
          <q-checkbox v-model="model.disruptive" label="Driftstörande" />
        </div>
      </div>
    </template>
  </MasterTable>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import MasterTable from '@/components/master/MasterTable.vue'
import {
  required as requiredRule,
  booleanTrueRequired,
} from '@/common/formValidationRules'
import { useVehicleDefectCode } from '@/composable/useVehicleDefectCode'
import { VehicleDefectCode } from '@/types/vehicle-defect-code'

export default defineComponent({
  name: 'VehicleDefectCodes',

  components: {
    MasterTable,
  },

  setup() {
    const {
      loading: loadingStateDepots,
      data: vehicleDefectCodes,
      add,
      update,
      remove,
      fetchAll,
    } = useVehicleDefectCode()

    fetchAll()

    function createFormModel(): VehicleDefectCode {
      return {
        defectCode: '',
        description: '',
        disruptive: false,
      }
    }
    const model = ref<VehicleDefectCode>(createFormModel())

    function resetFormModel() {
      model.value = createFormModel()
    }

    const columns = [
      {
        name: 'defectCode',
        label: 'Kod',
        align: 'left',
        field: 'defectCode',
        sortable: true,
      },
      {
        name: 'description',
        label: 'Beskrivning',
        align: 'left',
        field: 'description',
        sortable: true,
      },
      {
        name: 'disruptive',
        label: 'Driftstörande',
        align: 'left',
        field: 'disruptive',
        format: (x: boolean) => (x ? 'Ja' : ''),
        sortable: true,
      },
    ]

    const loading = computed(() => {
      return {
        ...loadingStateDepots.value,
      }
    })

    const exportData = computed(() => {
      return vehicleDefectCodes.value.map((item) => {
        return {
          Kod: item.defectCode,
          Beskrivning: item.description,
          Driftstörande: item.disruptive,
        }
      })
    })

    return {
      columns,
      model,
      requiredRule,
      booleanTrueRequired,
      vehicleDefectCodes,
      add,
      remove,
      update,
      loading,
      resetFormModel,
      exportData,
    }
  },
})
</script>
