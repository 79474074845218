
import { defineComponent, computed, ref } from 'vue'
import QTable from 'quasar/src/components/table/QTable.js';
import MasterTable from '@/components/master/MasterTable.vue'
import {
  required as requiredRule,
  minCharactersRule,
} from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'
import { Statusbar } from '@/types/statusbar'
import { useStatusbar } from '@/composable/useStatusbar'
import { useProfile } from '@/composable/useProfile'

export default defineComponent({
  name: 'MasterStatusbar',

  components: {
    MasterTable,
  },

  setup() {
    const { currentProject } = useProfile()

    const { loading, data, add, remove, update } = useStatusbar(
      ['gotalandstag', 'krosatag'].includes(currentProject.value?.name || '')
    )

    const model = ref<Statusbar>({
      uuid: uuidv4(),
      title: '',
      body: '',
    })

    function resetFormModel() {
      model.value = { uuid: uuidv4(), title: '', body: '' }
    }

    const columns: QTable['columns'] = [
      {
        name: 'title',
        label: 'Titel',
        align: 'left',
        field: 'title',
        sortable: true,
      },
      {
        name: 'body',
        label: 'Brödtext',
        align: 'left',
        field: 'body',
        sortable: true,
      },
    ]

    const exportData = computed(() => {
      interface ExportRow {
        [key: string]: unknown
      }

      return data.value.map((status) => {
        return columns.reduce<ExportRow>((acc, column) => {
          const value = status[column.field as keyof Statusbar]
          acc[column.label] =
            column.format && value ? column.format(value, null) : value
          return acc
        }, {})
      })
    })

    return {
      minCharactersRule: minCharactersRule(8),
      columns,
      model,
      requiredRule,
      resetFormModel,
      data,
      exportData,
      loading,
      add,
      update,
      remove,
    }
  },
})
