import { Ref, ref, ComputedRef, computed } from 'vue'
import { useLoading, Loading } from '@/composable/useLoading'
import { VehicleDefectCode } from '@/types/vehicle-defect-code'
import { getVehicleDefectCodes } from '@/api/vehicle-defect-code/getVehicleDefectCodes'
import { deleteVehicleDefectCode } from '@/api/vehicle-defect-code/deleteVehicleDefectCode'
import { createVehicleDefectCode } from '@/api/vehicle-defect-code/createVehicleDefectCode'
import { updateVehicleDefectCode } from '@/api/vehicle-defect-code/updateVehicleDefectCode'

interface UseVehcielDefectCode {
  data: Ref<VehicleDefectCode[]>
  fetchAll: () => Promise<VehicleDefectCode[]>
  remove: (uuid: string) => Promise<null>
  add: (body: VehicleDefectCode) => Promise<VehicleDefectCode>
  update: (body: VehicleDefectCode) => Promise<VehicleDefectCode>
  loading: ComputedRef<Loading>
}

const data = ref<VehicleDefectCode[]>([])

export function useVehicleDefectCode(
  initFetchAll?: boolean
): UseVehcielDefectCode {
  const { state } = useLoading()

  async function fetchAll() {
    state.getAll = true
    return new Promise<VehicleDefectCode[]>((resolve, reject) => {
      getVehicleDefectCodes()
        .then(({ data: vehicleDefectCode }) => {
          data.value = vehicleDefectCode
          resolve(vehicleDefectCode)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteVehicleDefectCode(uuid)
        .then(() => {
          data.value = data.value.filter((item) => item.defectCode !== uuid)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  async function add(body: VehicleDefectCode) {
    state.create = true
    return new Promise<VehicleDefectCode>((resolve, reject) => {
      createVehicleDefectCode(body)
        .then(({ data: vehicleDefectCode }) => {
          data.value.push(vehicleDefectCode)
          resolve(vehicleDefectCode)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: VehicleDefectCode) {
    state.update = true
    return new Promise<VehicleDefectCode>((resolve, reject) => {
      const updateBody: Partial<VehicleDefectCode> = {
        description: body.description,
        disruptive: body.disruptive,
      }
      updateVehicleDefectCode(body.defectCode, updateBody)
        .then(({ data: vehicleDefeectCode }) => {
          data.value = data.value.map((item) => {
            if (item.defectCode === vehicleDefeectCode.defectCode) {
              return {
                ...item,
                ...vehicleDefeectCode,
              }
            }

            return item
          })
          resolve(vehicleDefeectCode)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  if (initFetchAll) {
    fetchAll()
  }

  return {
    data,
    fetchAll,
    remove,
    add,
    update,
    loading: computed(() => state),
  }
}
