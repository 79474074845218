import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { VehicleDefectCode } from '@/types/vehicle-defect-code'

export function updateVehicleDefectCode(
  defectCode: string,
  body: Partial<VehicleDefectCode>
): Promise<AxiosResponse<VehicleDefectCode>> {
  return instance.put(`/vehicle-defect-code/${defectCode}`, body)
}
