<template>
  <MasterTable
    title="Statusbar"
    :rows="data"
    :columns="columns"
    :loading="loading"
    :add="add"
    :update="update"
    :remove="remove"
    :reset-form-model="resetFormModel"
    :form-model="model"
    :export-data="exportData"
    :force-show-add="data.length < 1"
    :force-show-update="true"
    :force-show-delete="true"
    @update-form-model="(row) => (model = row)"
    remove-item-text-prop="statusbar"
    :copy="false"
  >
    <template v-slot:form>
      <div class="row q-pa-md" style="width: 450px">
        <div class="col-12">
          <q-input
            autocomplete="off"
            dense
            v-model="model.title"
            type="text"
            label="Titel"
            :rules="[requiredRule]"
          />
        </div>
        <div class="col-12">
          <q-input
            autocomplete="off"
            dense
            type="textarea"
            v-model="model.body"
            label="Brödtext"
            :rules="[requiredRule]"
          />
        </div>
      </div>
    </template>
  </MasterTable>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import { QTable } from 'quasar'
import MasterTable from '@/components/master/MasterTable.vue'
import {
  required as requiredRule,
  minCharactersRule,
} from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'
import { Statusbar } from '@/types/statusbar'
import { useStatusbar } from '@/composable/useStatusbar'
import { useProfile } from '@/composable/useProfile'

export default defineComponent({
  name: 'MasterStatusbar',

  components: {
    MasterTable,
  },

  setup() {
    const { currentProject } = useProfile()

    const { loading, data, add, remove, update } = useStatusbar(
      ['gotalandstag', 'krosatag'].includes(currentProject.value?.name || '')
    )

    const model = ref<Statusbar>({
      uuid: uuidv4(),
      title: '',
      body: '',
    })

    function resetFormModel() {
      model.value = { uuid: uuidv4(), title: '', body: '' }
    }

    const columns: QTable['columns'] = [
      {
        name: 'title',
        label: 'Titel',
        align: 'left',
        field: 'title',
        sortable: true,
      },
      {
        name: 'body',
        label: 'Brödtext',
        align: 'left',
        field: 'body',
        sortable: true,
      },
    ]

    const exportData = computed(() => {
      interface ExportRow {
        [key: string]: unknown
      }

      return data.value.map((status) => {
        return columns.reduce<ExportRow>((acc, column) => {
          const value = status[column.field as keyof Statusbar]
          acc[column.label] =
            column.format && value ? column.format(value, null) : value
          return acc
        }, {})
      })
    })

    return {
      minCharactersRule: minCharactersRule(8),
      columns,
      model,
      requiredRule,
      resetFormModel,
      data,
      exportData,
      loading,
      add,
      update,
      remove,
    }
  },
})
</script>
